import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "delete-a-tab"
    }}>{`Delete a Tab`}</h1>
    <hr></hr>
    <p>{`To delete a Tab from your mobile app or template, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Click on the mobile app or template where you want to delete the tab.  `}</li>
      <li parentName="ol">{`Select the tab from the Tab Manager list that you wish to delete. `}</li>
      <li parentName="ol">{`Select Delete Tab at the top of Tap Manager. `}</li>
    </ol>
    <p><img alt="CMS Delete Tab" src={require("./images/tab_manager_delete.png")} />{` `}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Either confirm that you wish to delete the tab by selecting 'Ok' or abort the action by selecting 'Cancel' in the pop up window. `}<em parentName="li">{`If you delete the tab, all content items within the tab and sub tabs will be deleted as well.`}</em></li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to update your app.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      